import styled, { css } from 'styled-components';
import Select from 'react-dropdown-select';
import { inputGeneric, sublistItemInner } from 'app/theme';
import BlockMoviesFilter from 'app/components/MoviesDatesFilter/styles';
import { textTransform } from 'utils';

export const StyledSelect = styled(Select).attrs({
  className: 'f-default__select',
  values: ({ values }) => {

    if (values && Array.isArray(values)) {
      return values.filter(v => v != null);
    }
    return [];
  },
})`
  &.react-dropdown-select {
    ${inputGeneric}
    padding: 0;
    outline: 0;

    ${props =>
    props.uppercased &&
    css`
        [title] .f-default__select-label,
        .l-options__item-label {
        text-transform: ${textTransform};
        }
      `};

    ${props =>
    props.error &&
    css`
        border-color: ${({ theme }) => theme.color.rgbaAccent('.8')};
        box-shadow: 0 0 8px 0 ${({ theme }) => theme.color.rgbaAccent('.7')};

        &:hover,
        &:focus-within {
          border-color: ${({ theme }) => theme.color.rgbaAccent('.8')};
          box-shadow: 0 0 8px 0 ${({ theme }) => theme.color.rgbaAccent('.7')};
        }
      `}

    ${props =>
    !props.error &&
    css`
        &:hover,
        &:focus-within {
          box-shadow: 0 0 8px 0 ${({ theme }) => theme.color.rgbaWhite('.24')};
          border-color: ${({ theme }) => theme.color.rgbaWhite('.8')};
        }
      `}
  }

  .react-dropdown-select-dropdown {
    top: calc(100% + 3px);
    border: 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-color: ${({ theme }) => theme.color.bgPopup};
    animation: smoothAppearance 0.2s ease-out;
    width:100%;
  }

  .react-dropdown-select-content {
    display: none;
  }

  ${BlockMoviesFilter} &.react-dropdown-select {
    ${({ isHidden }) => (isHidden ? 'display: none;' : 'display: inline-flex;')}
    width: auto;

    .react-dropdown-select-dropdown {
      min-width: 288px;
      max-height: 307px;
      right: 0;
      left: auto;
    }
  }

  ${BlockMoviesFilter} &.react-dropdown-select:nth-child(2) {
    margin: 0 0 0 ${({ theme }) => theme.indent.l};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    ${BlockMoviesFilter} &.react-dropdown-select {
      max-width: calc(50% - ${({ theme }) => theme.indent.s});

      ${({ isHidden }) =>
    isHidden &&
    `
        & + .react-dropdown-select {
          width: 100%;
          max-width: none;
        }
      `}
    }

    ${BlockMoviesFilter} &.react-dropdown-select:first-child {
      text-align: left;

      .react-dropdown-select-dropdown {
        left: 0;
      }
    }

    ${BlockMoviesFilter} &.react-dropdown-select:only-child {
      width: 100%;
      max-width: none;
    }

    ${BlockMoviesFilter} &.react-dropdown-select:nth-child(2) {
      margin: 0;
      text-align: left;
    }
  }
`;

export const StyledHandle = styled.div`
  width: 100%;
  padding: 8px 32px 8px 16px;
  border-radius: 10px;
  position: relative;
  color: ${({ isRedLabel, theme }) =>
    isRedLabel ? theme.color.textPrimary : theme.color.rgbaWhite('.8')};
  cursor: default;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > .svg-location,
  & > .svg-calendar {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: ${({ theme }) => theme.indent.s};
    ${({ isRedLabel, theme }) =>
    `fill: ${isRedLabel ? theme.color.accent : theme.color.rgbaWhite('.8')};`}
  }

  & > .svg-location {
    width: 14px;
  }

  .f-default__select-label {
    vertical-align: middle;
    text-transform: none;

    &._placeholder {
      color: ${({ theme }) => theme.color.labelColor};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    .f-default__select-label {
      font-size:16px;
    }
  }
  &::after {
    content: '';
    height: 5px;
    position: absolute;
    top: calc(50% - 2.5px);
    right: 16px;
    border: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.color.borderLight};
    transition: transform 0.15s ease-out;
    ${props => props.isOpen && 'transform: scaleY(-1) translateY(5px)'};
  }
`;

export const ListOptions = styled.ul.attrs({
  className: 'l-options'
})``;

export const ListOptionsItem = styled.li.attrs({
  className: 'l-options__item'
})`
  display: block;
  font-size: 0;

  & > .l-options__item-inner {
    ${sublistItemInner}

    ${props =>
    props.selected &&
    css`
        color: ${({ theme }) => theme.color.accent};
        cursor: default;
        pointer-events: none;
      `}
      ${props =>
    props.group &&
    css`
          color: grey;
          cursor: default;
          pointer-events: none;
        `}
  }

  .l-options__item-label {
    backface-visibility: hidden;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    .l-options__item-label{
      font-size:16px;
    }
  }
  .l-options__item-mark {
    margin-left: 5px;
    font-size: 0;

    & > .svg-checkmark {
      width: 12px;
      height: 12px;
      fill: ${({ theme }) => theme.color.accent};
    }
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.borderDark};
  }
  .l-options__item-inner {
    text-transform:none;
  }

  &:first-child > .l-options__item-inner {
    border-radius: 7px 7px 0 0;
  }

  &:last-child > .l-options__item-inner {
    border-radius: 0 0 7px 7px;
  }

  &:only-child > .l-options__item-inner {
    border-radius: 7px;
  }
`;
