/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink, generatePath, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfigContext from 'app/context/config';
import { onClickBehaviorMobile } from 'app/utils';
import { useConfig, useRouter } from 'app/hooks';
import { IsMobile, NavListMobile, NavList, NavListItem, SubList, SubListItem } from './styles';
import MobileImg from '../../../assets/images/mobileNav.png';
import LeftArrowIcon from '../../../assets/images/leftArrowIcon2.png';
import CloseImg from '../../../assets/images/close.png';
import { Modal } from 'app/components';
import useModal from 'app/hooks/useModal';

const propTypes = {
  cinemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

const MobileNav = ({ cinemas, locations, savedLocation, isLoggedIn, pathname }) => {
  const [t, i18n] = useTranslation();
  const { routes, showingOptions } = useContext(ConfigContext);
  // eslint-disable-next-line no-unused-vars
  const [cinemasToShow, setCinemas] = useState([]);
  const [isOpen, setIsOpen] = useState(false);


  const {
    // giftCardShop,
    streamingUrl,
    giftCardShopEN,
    giftCardShopDE,
    vodNotLoggedInUrlEN,
    vodNotLoggedInUrlDE,
    country
  } = useConfig();
  const history = useHistory();
  const refreshToken = localStorage.getItem('refreshToken');
  const streamingUrlSet = streamingUrl + "?refresh_token=" + refreshToken;
  // eslint-disable-next-line no-unused-vars
  const [streamingFunction, showStreamingFunction, hideStreamingFunction] = useModal('streamingFunction');

  const isMobile = window.innerWidth < 992

  const location = useLocation();
  // console.log("location",location.pathname);
  const clubList = useMemo(
    () => [
      {
        id: 1,
        label: 'bonusCard',
        name: t('common.bonus_card'),
        route: routes.bonusCardInfo.pattern
      },
      {
        id: 2,
        label: 'familyFilmclub',
        name: t('common.family_film_club'),
        route: routes.familyFilmClub.pattern
      },
      {
        id: 3,
        label: 'competitions',
        name: t('common.competitions'),
        route: routes.competitionInfo.pattern
      }
    ],
    [t]
  );

  const giftcardList = useMemo(
    () => [
      {
        id: 1,
        label: 'giftcardShop',
        name: t('common.giftcard_shop'),
        route: null
      },
      {
        id: 2,
        label: 'giftcardInfo',
        name: t('common.giftcard_info'),
        route: routes.giftCardInfo.pattern
      }
    ],
    [t]
  );
  let data = sessionStorage.getItem("movieCinemaId");

  useEffect(() => {
    if (savedLocation === 'all') {
      setCinemas(cinemas);
    } else {
      /* eslint-disable no-param-reassign */
      const items = locations.reduce((res, l) => {
        if (l.id.toString() === savedLocation) {
          res = l.items;
        }

        return res;
      }, []);
      /* eslint-enable no-param-reassign */

      if (items.length) {
        setCinemas(cinemas.filter(c => items.some(i => i.toString() === c.id)));
      }
    }
  }, [locations, cinemas, savedLocation]);

  const OpenMenu = () => {
    setIsOpen(!isOpen);
  };
  const goBackLink = () => {
    // console.log("data",data);
    if (location.pathname?.split('/')[4] === "tickets") {
      history.goBack();
    }
    else if (location.pathname?.split('/')[4] === 'seats') {
      history.replace(`/purchase/wizard/${data}/tickets`)
    }
    else if (location.pathname?.split('/')[4] === 'checkout') {
      history.replace(`/purchase/wizard/${data}/seats`)
    }
    // history.goBack();
    // console.log("first_step")
  }

  const findArrayElementByTitle = (arrayList, pathname) => {
    let result = '-';

    arrayList.find(element => {
      if (element.route === pathname) {
        if (Object.keys(element).length > 0) {
          result = '_hover';
        } else {
          result = '-';
        }
      }
      return null;
    });

    return result;
  };

  useEffect(() => {
    window.onclick = function (event) {
      if (document.getElementById('mobileNavigation').contains(event.target)) {
        // Clicked in box
      } else {
        // Clicked outside the box
        setIsOpen(false);
      }
    }
  }, [])

  return (
    <>
      <IsMobile id="mobileNavigation">
        {
          isOpen ? (
            <img src={CloseImg} className="mobileMenuClose" id='image' onClick={OpenMenu} alt="menu-logo" />
          ) : (
            location.pathname?.split('/')[4] === "tickets" || location.pathname?.split('/')[4] === "seats" || location.pathname?.split('/')[4] === "checkout" ?
              <img src={LeftArrowIcon} className="mobileMenu LeftArrowIcon" id='image' onClick={goBackLink} alt="menu-logo" />
              : <img src={MobileImg} className="mobileMenu" id='image' onClick={OpenMenu} alt="menu-logo" />
          )
        }
        {
          isOpen && (
            <div>
              <NavListMobile>
                <Modal
                  isOpen={streamingFunction}
                  close={hideStreamingFunction}
                  title={"Streaming"}
                >
                  <h3>{t('common.streaming_condition')}</h3>
                </Modal>
                <NavList>
                  {showingOptions?.member && !isMobile && country === "rou" &&
                    (
                      <NavListItem
                        {...onClickBehaviorMobile}
                        chevron
                        isLoggedIn={isLoggedIn}
                        className={findArrayElementByTitle(clubList, pathname)}
                      >
                        <span className="_with-chevron chevron_arrow">{t('common.club')}</span>
                        <SubList>
                          {clubList.map(
                            ({ id, label, name, route }) =>
                              showingOptions?.club?.[label] && (
                                <SubListItem key={id} activeClassName="_active">
                                  <NavLink
                                    className="l-sublist__link"
                                    to={{
                                      pathname: generatePath(route)
                                    }}
                                    activeClassName="_active"
                                    exact
                                    onClick={OpenMenu}
                                  >
                                    {name.toLowerCase()}
                                  </NavLink>
                                </SubListItem>
                              )
                          )}
                        </SubList>
                      </NavListItem>
                    )}
                  {showingOptions?.giftcards && process.env(
                    <NavListItem
                      {...onClickBehaviorMobile}
                      chevron
                      isLoggedIn={isLoggedIn}
                      className={findArrayElementByTitle(giftcardList, pathname)}
                    >
                      <span className="_with-chevron chevron_arrow">{t('common.giftcards')}</span>
                      <SubList>
                        {giftcardList.map(
                          ({ id, label, name, route }) =>
                            showingOptions?.giftCards?.[label] && (
                              <SubListItem key={id} activeClassName="_active">
                                {name === t('common.giftcard_shop') ? (
                                  <a
                                    href={i18n.language === 'en' || i18n.language === 'en-US' ? giftCardShopEN : giftCardShopDE}
                                    className="l-sublist__link"
                                    activeClassName="_active"
                                    exact
                                    onClick={OpenMenu}
                                  >
                                    {' '}
                                    {name.toLowerCase()}
                                  </a>
                                ) : (
                                  <NavLink
                                    className="l-sublist__link"
                                    to={{
                                      pathname: generatePath(route)
                                    }}
                                    activeClassName="_active"
                                    exact
                                    onClick={OpenMenu}
                                  >
                                    {name.toLowerCase()}
                                  </NavLink>
                                )}
                              </SubListItem>
                            )
                        )}
                      </SubList>
                    </NavListItem>
                  )}

                  {showingOptions?.cinemaRent && (
                    <NavListItem isLoggedIn={isLoggedIn}>
                      <NavLink
                        to={{
                          pathname: routes.cinemaRent.pattern
                        }}
                        activeClassName="_active"
                        exact
                        onClick={OpenMenu}
                      >
                        {t('common.cinema_rent')}
                      </NavLink>
                    </NavListItem>
                  )}
                  {showingOptions?.streaming && (
                    <NavListItem isLoggedIn={isLoggedIn}>
                      {isLoggedIn ?
                        <a
                          activeClassName="_active"
                          exact
                          href={streamingUrlSet}
                        >
                          {t('common.streaming')}
                        </a>
                        : <a
                          activeClassName="_active"
                          exact
                          href={18n.language === 'en' ? vodNotLoggedInUrlEN : vodNotLoggedInUrlDE}
                        >
                          {t('common.streaming')}
                        </a>
                      }
                    </NavListItem>
                  )}
                </NavList>
              </NavListMobile>
            </div>
          )
        }
      </IsMobile>
    </>
  );
};

MobileNav.propTypes = propTypes;
export default MobileNav;
