import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyledHandle } from './styles';

const propTypes = {
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  isCalendar: PropTypes.bool,
  isLocation: PropTypes.bool,
  isCinema: PropTypes.bool,
  isTechnologies: PropTypes.bool,
  isVersions: PropTypes.bool,
  isRedLabel: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  values: PropTypes.arrayOf(PropTypes.object)
};

const defaultProps = {
  multi: false,
  placeholder: '...',
  isCalendar: false,
  isLocation: false,
  isCinema: false,
  isTechnologies: false,
  isVersions: false,
  isRedLabel: false,
  children: null,
  values: []
};

const getLabel = (propsValues, stateValues, multi) => {
  if (multi && propsValues && propsValues.length) {
    return propsValues.map(o => o?.label).join(', ');
  }

  if (multi && stateValues && stateValues.length) {
    return stateValues.map(o => o?.label).join(', ');
  }

  if (propsValues && propsValues.length) {
    return propsValues[0]?.label;
  }

  if (stateValues && stateValues.length) {
    return stateValues[0]?.label;
  }

  return null;
};

let calendarPropsFlag = null;

let locationPropsFlag = null;

let cinemaPropsFlag = null;

const Handle = (props, state, methods) => {
  const {
    multi,
    placeholder,
    isCalendar,
    isLocation,
    isCinema,
    isTechnologies,
    isVersions,
    isRedLabel,
    children,
    values
  } = props;
  const { dropdown } = state;
  const label = getLabel(values, state.values, multi);
  const labelClassName = `f-default__select-label ${!label ? '_placeholder' : ''}`;

  if (values?.length && state?.values?.length && state?.values[0]?.value !== values[0]?.value) {
    if (isCalendar && values[0]?.value !== calendarPropsFlag) {
      methods.addItem(values[0]);
    } else if (isLocation && values[0]?.value !== locationPropsFlag) {
      methods.addItem(values[0]);
    } else if (isCinema && values[0]?.value !== cinemaPropsFlag) {
      methods.addItem(values[0]);
    }
  }

  if (isCalendar) {
    calendarPropsFlag = values[0].value;
  } else if (isLocation) {
    locationPropsFlag = values[0].value;
  } else if (isCinema) {
    cinemaPropsFlag = values[0].value;
  } else if (isTechnologies && values.length && values[0] === 'clear') {
    methods.clearAll();
  } else if (isVersions && values.length && values[0] === 'clear') {
    methods.clearAll();
  }

  return (
    <StyledHandle
      title={label}
      isRedLabel={isRedLabel}
      isOpen={dropdown}
      onClick={() => methods.dropDown('toggle')}
    >
      {multi ? (
        <span className={labelClassName}>{(label && (isVersions ? label : label.toLowerCase())) || placeholder}</span>
      ) : (
        <Fragment>
          {children}
          {/* <span className={labelClassName}>{(label && (isVersions ? label : label.toLowerCase())) || placeholder}</span> */}
          <span className={labelClassName}>{(label) || placeholder}</span>
        </Fragment>
      )}
    </StyledHandle>
  );
};

Handle.propTypes = propTypes;
Handle.defaultProps = defaultProps;
export default Handle;
