/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function (value) {
  const [t] = useTranslation();
  const country = process.env.REACT_APP_COUNTRY;

  const options = useMemo(() => {
    const baseOptions = [
      { label: t('common.male'), value: 'Male', key: 'male' },
      { label: t('common.female'), value: 'Female', key: 'female' },
    ];

    if (country !== "rou") {
      baseOptions.push({ label: t('common.diverse'), value: 'Unknown', key: 'unknown' });
    }

    if (country !== "rou" && country !== "srb") {
      baseOptions.push({ label: t('common.no_information'), value: 'Null', key: 'null' });
    }

    return baseOptions;
  }, [country, t]);
  const valueOption = useMemo(() => [options.find(o => o.value === value)], [value]);

  return [options, valueOption];
}
